<template>
  <c-box>
    <c-box
      d="flex"
      justify-content="space-between"
      align-items="center"
    >
      <c-text
        :font-size="['18px', '20px']"
        :font-weight="['500', 'Bold']"
      >
        Detail Program
      </c-text>
      <TextChangeProgram
        :route-change-program="routeChangeProgram"
      />
    </c-box>
    <c-divider />
    <c-flex
      :gap="['10px', '16px']"
    >
      <c-image
        object-fit="cover"
        :src="photoUrl || require('@/assets/images/image-gray.png')"
        alt="Image Program"
        :h="['89px', '153px']"
        :w="['70px', '120px']"
        rounded="12px"
      />
      <c-flex
        flex-direction="column"
        justify-content="center"
        width="100%"
      >
        <c-box line-height="1.3">
          <c-text
            :display="['inline', 'inline']"
            :font-size="['18px', '28px']"
            :font-weight="['500', 'Bold']"
            color="neutral.superDarkGray"
          >
            {{ name }} {{ ' ' }}
          </c-text>
          <c-text
            :vertical-align="['initial','text-bottom']"
            :display="['inline', 'inline']"
            :font-size="['18px', '16px']"
            :font-weight="['500', '400']"
            color="neutral.superDarkGray"
          >
            {{ subName }}
          </c-text>
        </c-box>

        <router-link
          :to="moreInfoUrl"
        >
          <c-text
            :font-size="['12px', '16px']"
            :font-weight="['400', 'Regular']"
            d="flex"
            gap="8px"
            align-items="center"
            cursor="pointer"
          >
            Selengkapnya
            <c-box
              :d="['none', 'block']"
              margin-bottom="-1px"
            >
              <Icon
                icon="mdi:arrow-right"
                height="16"
                width="16"
              />
            </c-box>
          </c-text>
        </router-link>
        <c-text
          :font-size="['18px', '20px']"
          :font-weight="['700', 'Bold']"
          color="#008C81"
        >
          {{ formatCurrency(price) }}
        </c-text>
      </c-flex>
    </c-flex>
    <c-divider />
    <c-box
      v-if="!hasPhoneNumber"
      color="#008C81"
      d="flex"
      flex-dir="column"
      gap="8px"
      mt="16px"
    >
      <c-text
        :font-size="['14px', '16px']"
        :font-weight="['500', 'Regular']"
        :color="isValidPhone ? '#888888' : '#D32737'"
      >
        Nomor Telepon (WhatsApp)
      </c-text>
      <c-box
        d="flex"
        gap="8px"
        align-items="center"
      >
        <c-input-group
          size="lg"
          w="100%"
        >
          <c-input-left-element>
            <Icon
              icon="fa:phone"
              :color="isValidPhone ? '#888888' : '#D32737'"
              height="20"
              width="20"
            />
          </c-input-left-element>
          <c-input
            rounded="8px"
            type="tel"
            :color="isValidPhone ? '#888888' : '#D32737'"
            placeholder="Masukkan nomor telepon"
            :font-size="['14px', '18px']"
            :font-weight="['500', 'Medium']"
            :value="phone"
            :border-color="isValidPhone ? '#888888' : '#D32737'"
            @change="$emit('set-phone', $event)"
            @keypress="($event) => {
              let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
              if ((keyCode < 48 || keyCode > 57)) { // 46 is dot
                $event.preventDefault();
              }
            }"
          />
        </c-input-group>
      </c-box>

      <CBox
        as="p"
        :font-size="['12px', '16px']"
        :font-weight="['400', 'Regular']"
        d="flex"
        gap="8px"
        align-items="center"
        :color="isValidPhone ? '#0C72E0' : '#D32737'"
      >
        <c-box margin-top="-1px">
          <Icon
            icon="ri:question-fill"
            :color="isValidPhone ? '#0C72E0' : '#D32737'"
            height="15"
            width="15"
          />
        </c-box>
        {{ infoMessagePhone || 'Masukkan nomor Whatsapp yang valid agar dapat terhubung dengan ahli gizi' }}
      </CBox>
    </c-box>
    <c-box
      v-if="!couponDetail?.isValid || couponDetail?.id === ''"
      color="#008C81"
      d="flex"
      flex-dir="column"
      gap="8px"
      mt="16px"
    >
      <c-text
        :font-size="['14px', '16px']"
        :font-weight="['500', 'Regular']"
        :color="_colorCoupon"
      >
        Kode Kupon
      </c-text>
      <c-box
        d="flex"
        gap="8px"
        align-items="center"
      >
        <c-input-group
          size="lg"
          w="100%"
        >
          <c-input-left-element>
            <Icon
              icon="mdi:coupon"
              :color="_colorCoupon"
              height="20"
              width="20"
            />
          </c-input-left-element>
          <c-input
            rounded="8px"
            type="text"
            :color="_colorCoupon"
            placeholder="Masukkan kode kupon"
            :font-size="['14px', '18px']"
            :font-weight="['500', 'Medium']"
            :value="couponCode"
            :disabled="couponStatus === 'success'"
            :border-color="couponStatus === 'failed' ? '#D32737' : 'inherit'"
            @change="$emit('set-coupon-code', $event)"
          />
        </c-input-group>
        <c-button
          :font-size="['14px', '18px']"
          :font-weight="['500', 'Medium']"
          :variant-color="couponStatus === 'success' ? 'red' : 'primary'"
          rounded="1000px"
          size="md"
          px="20px"
          :h="['40px', '48px']"
          :disabled="isApplyingCoupon || !couponCode"
          @click="$emit('handle-click-check-coupon')"
        >
          {{ couponStatus === 'success' ? "Hapus" : "Terapkan" }}
        </c-button>
      </c-box>
      <LearnMore
        :color-coupon="_colorCoupon"
        :status-applied-coupon="couponStatus"
      />
    </c-box>

    <c-flex
      v-if="couponDetail?.isValid"
      border-radius="12px"
      background-color="success.50"
      :padding="['12px' ,'20px']"
      :gap="['10px', '20px']"
      :margin="['16px 0', '20px 0']"
      align-items="center"
    >
      <c-box
        :width="['20px', '30px']"
        :height="['20px', '30px']"
        :margin-inline="['5px', '10px']"
        flex-shrink="0"
      >
        <inline-svg
          :src="require('@/assets/icons/icon-circle-check.svg')"
          width="100%"
          height="100%"
          fill="#008C81"
        />
      </c-box>
      <c-flex
        flex="1"
        flex-direction="column"
        :gap="['4px', '8px']"
      >
        <c-text
          color="primary.400"
          :font-size="['16px', '18px']"
          font-weight="600"
          text-transform="uppercase"
        >
          {{ couponCode }}
        </c-text>
        <c-text
          color="primary.400"
          :font-size="['12px', '16px']"
        >
          Kupon berhasil digunakan! Kamu mendapat 
          <c-box
            as="span"
            font-weight="600"
          >
            {{ couponDetail?.isSkipPayment ? 'program gratis' : 'potongan harga khusus' }}
          </c-box> 
        </c-text>
      </c-flex>
      <c-box
        :width="['20px', '30px']"
        :height="['20px', '30px']"
        :margin-inline="['5px', '10px']"
        flex-shrink="0"
        cursor="pointer"
        border-radius="4px"
        :_hover="{
          backgroundColor: 'neutral.superLightGray',
        }"
        @click="$emit('handle-click-check-coupon')"
      >
        <inline-svg
          :src="require('@/assets/icon-close-black.svg')"
          height="100%"
          width="100%"
          stroke="#888888"
        />
      </c-box>
    </c-flex>
    <c-divider :d="['none', 'block']" />
  </c-box>
</template>

<script>
import TextChangeProgram from '@/views/client/cart/check-order/_widgets/text-change-program.vue'
import { CBox, CText, CDivider, CButton,
  CInput, CInputGroup, CInputLeftElement, CImage,
} from '@chakra-ui/vue'
import LearnMore from '@/views/client/cart/check-order/_widgets/learn-more.vue'
import { formatCurrency } from '@/utils/format-currency'
import { Icon } from '@iconify/vue2'

export default {
  name: 'ElementCheckOrderInfoProgram',
  components: { Icon, LearnMore, CText, CBox, CDivider, CButton, TextChangeProgram, CInput, CInputGroup, CInputLeftElement, CImage },
  props: {
    photoUrl: {
      required: true,
      type: String,
    },
    name: {
      required: true,
      type: String,
    },
    subName: {
      required: true,
      type: String,
    },
    routeChangeProgram: {
      required: true,
      type: [String, Object],
    },
    moreInfoUrl: {
      required: true,
      type: [String, Object],
    },
    price: {
      required: true,
      type: Number,
      default: 0,
    },
    isApplyingCoupon: {
      required: true,
      type: Boolean,
      default: false,
    },
    couponCode: {
      required: true,
      type: String,
    },
    couponStatus: {
      required: true,
      default: 'not-applied',
      validator: function(value) {
        return ['not-applied', 'success', 'failed'].includes(value)
      },
    },
    hasPhoneNumber: {
      required: true,
      type: Boolean,
    },
    isValidPhone: {
      required: true,
      type: Boolean,
    },
    infoMessagePhone: {
      type: String,
      default: '',
    },
    phone: {
      required: true,
      type: String,
    },
    couponDetail: {
      required: true,
      type: Object,
    },
  },
  emits: ['set-coupon-code', 'handle-click-check-coupon', 'set-phone'],
  computed: {
    _colorCoupon() {
      switch (this.couponStatus) {
        case 'not-applied':
          return '#888888'
        case 'success':
          return '#008C81'
        case 'failed':
          return '#D32737'
        default:
          return '#888888'
      }
    },
  },
  methods: { formatCurrency },
}
</script>
