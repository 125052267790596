var render = function () {
  var _vm$couponDetail, _vm$couponDetail2, _vm$couponDetail3, _vm$couponDetail4;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('c-box', {
    attrs: {
      "d": "flex",
      "justify-content": "space-between",
      "align-items": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['18px', '20px'],
      "font-weight": ['500', 'Bold']
    }
  }, [_vm._v(" Detail Program ")]), _c('TextChangeProgram', {
    attrs: {
      "route-change-program": _vm.routeChangeProgram
    }
  })], 1), _c('c-divider'), _c('c-flex', {
    attrs: {
      "gap": ['10px', '16px']
    }
  }, [_c('c-image', {
    attrs: {
      "object-fit": "cover",
      "src": _vm.photoUrl || require('@/assets/images/image-gray.png'),
      "alt": "Image Program",
      "h": ['89px', '153px'],
      "w": ['70px', '120px'],
      "rounded": "12px"
    }
  }), _c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "justify-content": "center",
      "width": "100%"
    }
  }, [_c('c-box', {
    attrs: {
      "line-height": "1.3"
    }
  }, [_c('c-text', {
    attrs: {
      "display": ['inline', 'inline'],
      "font-size": ['18px', '28px'],
      "font-weight": ['500', 'Bold'],
      "color": "neutral.superDarkGray"
    }
  }, [_vm._v(" " + _vm._s(_vm.name) + " " + _vm._s(' ') + " ")]), _c('c-text', {
    attrs: {
      "vertical-align": ['initial', 'text-bottom'],
      "display": ['inline', 'inline'],
      "font-size": ['18px', '16px'],
      "font-weight": ['500', '400'],
      "color": "neutral.superDarkGray"
    }
  }, [_vm._v(" " + _vm._s(_vm.subName) + " ")])], 1), _c('router-link', {
    attrs: {
      "to": _vm.moreInfoUrl
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['12px', '16px'],
      "font-weight": ['400', 'Regular'],
      "d": "flex",
      "gap": "8px",
      "align-items": "center",
      "cursor": "pointer"
    }
  }, [_vm._v(" Selengkapnya "), _c('c-box', {
    attrs: {
      "d": ['none', 'block'],
      "margin-bottom": "-1px"
    }
  }, [_c('Icon', {
    attrs: {
      "icon": "mdi:arrow-right",
      "height": "16",
      "width": "16"
    }
  })], 1)], 1)], 1), _c('c-text', {
    attrs: {
      "font-size": ['18px', '20px'],
      "font-weight": ['700', 'Bold'],
      "color": "#008C81"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.price)) + " ")])], 1)], 1), _c('c-divider'), !_vm.hasPhoneNumber ? _c('c-box', {
    attrs: {
      "color": "#008C81",
      "d": "flex",
      "flex-dir": "column",
      "gap": "8px",
      "mt": "16px"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['14px', '16px'],
      "font-weight": ['500', 'Regular'],
      "color": _vm.isValidPhone ? '#888888' : '#D32737'
    }
  }, [_vm._v(" Nomor Telepon (WhatsApp) ")]), _c('c-box', {
    attrs: {
      "d": "flex",
      "gap": "8px",
      "align-items": "center"
    }
  }, [_c('c-input-group', {
    attrs: {
      "size": "lg",
      "w": "100%"
    }
  }, [_c('c-input-left-element', [_c('Icon', {
    attrs: {
      "icon": "fa:phone",
      "color": _vm.isValidPhone ? '#888888' : '#D32737',
      "height": "20",
      "width": "20"
    }
  })], 1), _c('c-input', {
    attrs: {
      "rounded": "8px",
      "type": "tel",
      "color": _vm.isValidPhone ? '#888888' : '#D32737',
      "placeholder": "Masukkan nomor telepon",
      "font-size": ['14px', '18px'],
      "font-weight": ['500', 'Medium'],
      "value": _vm.phone,
      "border-color": _vm.isValidPhone ? '#888888' : '#D32737'
    },
    on: {
      "change": function change($event) {
        return _vm.$emit('set-phone', $event);
      },
      "keypress": function keypress($event) {
        var keyCode = $event.keyCode ? $event.keyCode : $event.which;

        if (keyCode < 48 || keyCode > 57) {
          // 46 is dot
          $event.preventDefault();
        }
      }
    }
  })], 1)], 1), _c('CBox', {
    attrs: {
      "as": "p",
      "font-size": ['12px', '16px'],
      "font-weight": ['400', 'Regular'],
      "d": "flex",
      "gap": "8px",
      "align-items": "center",
      "color": _vm.isValidPhone ? '#0C72E0' : '#D32737'
    }
  }, [_c('c-box', {
    attrs: {
      "margin-top": "-1px"
    }
  }, [_c('Icon', {
    attrs: {
      "icon": "ri:question-fill",
      "color": _vm.isValidPhone ? '#0C72E0' : '#D32737',
      "height": "15",
      "width": "15"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.infoMessagePhone || 'Masukkan nomor Whatsapp yang valid agar dapat terhubung dengan ahli gizi') + " ")], 1)], 1) : _vm._e(), !((_vm$couponDetail = _vm.couponDetail) !== null && _vm$couponDetail !== void 0 && _vm$couponDetail.isValid) || ((_vm$couponDetail2 = _vm.couponDetail) === null || _vm$couponDetail2 === void 0 ? void 0 : _vm$couponDetail2.id) === '' ? _c('c-box', {
    attrs: {
      "color": "#008C81",
      "d": "flex",
      "flex-dir": "column",
      "gap": "8px",
      "mt": "16px"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['14px', '16px'],
      "font-weight": ['500', 'Regular'],
      "color": _vm._colorCoupon
    }
  }, [_vm._v(" Kode Kupon ")]), _c('c-box', {
    attrs: {
      "d": "flex",
      "gap": "8px",
      "align-items": "center"
    }
  }, [_c('c-input-group', {
    attrs: {
      "size": "lg",
      "w": "100%"
    }
  }, [_c('c-input-left-element', [_c('Icon', {
    attrs: {
      "icon": "mdi:coupon",
      "color": _vm._colorCoupon,
      "height": "20",
      "width": "20"
    }
  })], 1), _c('c-input', {
    attrs: {
      "rounded": "8px",
      "type": "text",
      "color": _vm._colorCoupon,
      "placeholder": "Masukkan kode kupon",
      "font-size": ['14px', '18px'],
      "font-weight": ['500', 'Medium'],
      "value": _vm.couponCode,
      "disabled": _vm.couponStatus === 'success',
      "border-color": _vm.couponStatus === 'failed' ? '#D32737' : 'inherit'
    },
    on: {
      "change": function change($event) {
        return _vm.$emit('set-coupon-code', $event);
      }
    }
  })], 1), _c('c-button', {
    attrs: {
      "font-size": ['14px', '18px'],
      "font-weight": ['500', 'Medium'],
      "variant-color": _vm.couponStatus === 'success' ? 'red' : 'primary',
      "rounded": "1000px",
      "size": "md",
      "px": "20px",
      "h": ['40px', '48px'],
      "disabled": _vm.isApplyingCoupon || !_vm.couponCode
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('handle-click-check-coupon');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.couponStatus === 'success' ? "Hapus" : "Terapkan") + " ")])], 1), _c('LearnMore', {
    attrs: {
      "color-coupon": _vm._colorCoupon,
      "status-applied-coupon": _vm.couponStatus
    }
  })], 1) : _vm._e(), (_vm$couponDetail3 = _vm.couponDetail) !== null && _vm$couponDetail3 !== void 0 && _vm$couponDetail3.isValid ? _c('c-flex', {
    attrs: {
      "border-radius": "12px",
      "background-color": "success.50",
      "padding": ['12px', '20px'],
      "gap": ['10px', '20px'],
      "margin": ['16px 0', '20px 0'],
      "align-items": "center"
    }
  }, [_c('c-box', {
    attrs: {
      "width": ['20px', '30px'],
      "height": ['20px', '30px'],
      "margin-inline": ['5px', '10px'],
      "flex-shrink": "0"
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-circle-check.svg'),
      "width": "100%",
      "height": "100%",
      "fill": "#008C81"
    }
  })], 1), _c('c-flex', {
    attrs: {
      "flex": "1",
      "flex-direction": "column",
      "gap": ['4px', '8px']
    }
  }, [_c('c-text', {
    attrs: {
      "color": "primary.400",
      "font-size": ['16px', '18px'],
      "font-weight": "600",
      "text-transform": "uppercase"
    }
  }, [_vm._v(" " + _vm._s(_vm.couponCode) + " ")]), _c('c-text', {
    attrs: {
      "color": "primary.400",
      "font-size": ['12px', '16px']
    }
  }, [_vm._v(" Kupon berhasil digunakan! Kamu mendapat "), _c('c-box', {
    attrs: {
      "as": "span",
      "font-weight": "600"
    }
  }, [_vm._v(" " + _vm._s((_vm$couponDetail4 = _vm.couponDetail) !== null && _vm$couponDetail4 !== void 0 && _vm$couponDetail4.isSkipPayment ? 'program gratis' : 'potongan harga khusus') + " ")])], 1)], 1), _c('c-box', {
    attrs: {
      "width": ['20px', '30px'],
      "height": ['20px', '30px'],
      "margin-inline": ['5px', '10px'],
      "flex-shrink": "0",
      "cursor": "pointer",
      "border-radius": "4px",
      "_hover": {
        backgroundColor: 'neutral.superLightGray'
      }
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('handle-click-check-coupon');
      }
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icon-close-black.svg'),
      "height": "100%",
      "width": "100%",
      "stroke": "#888888"
    }
  })], 1)], 1) : _vm._e(), _c('c-divider', {
    attrs: {
      "d": ['none', 'block']
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }